import { useMemo } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { documentList } from '@store/atoms/document-list';
import { knowledgeAttributes } from '@store/atoms/knowledge-attributes';
import { workspace } from '@store/atoms/workspace';
import { getFileExt } from '@utils/file';

import { AssetType } from '../enums';
import { DataItem } from '../types';
import { useGetAssetsHierarchy } from './use-get-assets-hierarchy';
import { useGetBrandData } from './use-get-brand-data';
import { useGetKnowledgeAttribute } from './use-get-knowledge-attribute';
import { toDateFormat } from '@utils/date';
import { assetsHierarchy } from '@store/atoms/assets-hierarchy';

export const useGetGroupedBrandData = () => {
  const currentWorkspace = useRecoilValue(workspace);
  const knowledgeAttributeList = useRecoilValue(knowledgeAttributes);
  const assetsHierarchyList = useRecoilValue(assetsHierarchy);
  const setDocumentList = useSetRecoilState(documentList);

  const { brandAssets, isLoading: isLoadingBrandAssets } = useGetBrandData({
    enabled: !!currentWorkspace?.id,
  });

  const { isLoading: isAssetHierarchyLoading } = useGetAssetsHierarchy(
    currentWorkspace.id,
  );

  useGetKnowledgeAttribute();

  const logoItems = useMemo(() => {
    return brandAssets.reduce<DataItem[]>((accumulator, item) => {
      if (item.assetType === AssetType.LOGO) {
        accumulator.push({
          id: item.id,
          assetType: AssetType.LOGO,
          name: item?.name,
          url: item?.previewUrl || item?.url || '',
          createdBy: item.userName,
          createdAt: toDateFormat(item.createdAt),
          parentClientId: item.parentClient?.id,
          parentClientName: item.parentClient?.name,
          externalAssetId: item.metadata?.externalAssetId,
          isLocked: item.isLocked ? item.isLocked : false,
          thumbnailUrl: item?.thumbnailUrl || '',
        });
      }

      return accumulator;
    }, []);
  }, [brandAssets]);

  const fontItems = useMemo(() => {
    const filteredFonts = brandAssets.filter(
      item => item.assetType === AssetType.FONT,
    );

    return filteredFonts.map(item => {
      return {
        id: item.id,
        assetType: AssetType.FONT,
        name: item.name,
        createdBy: item.userName,
        createdAt: toDateFormat(item.createdAt),
        parentClientId: item.parentClient?.id,
        parentClientName: item.parentClient?.name,
        isLocked: item.isLocked ? item.isLocked : false,
      };
    });
  }, [brandAssets]);

  const colorItems = useMemo(() => {
    return brandAssets.reduce<DataItem[]>((accumulator, item) => {
      if (item.assetType === AssetType.COLORS) {
        accumulator.push({
          id: item.id,
          assetType: AssetType.COLORS,
          name: item.name,
          createdBy: item.userName,
          createdAt: toDateFormat(item.createdAt),
          details: item.metadata?.hex,
          parentClientId: item.parentClient?.id,
          parentClientName: item.parentClient?.name,
          isLocked: item.isLocked ? item.isLocked : false,
        });
      }

      return accumulator;
    }, []);
  }, [brandAssets]);

  const documentItems = useMemo(() => {
    const documentList = brandAssets
      .filter(item => {
        return item.assetType === AssetType.RAW_FILE;
      })
      .map(item => {
        return {
          id: item.id,
          type: getFileExt(item.url) === 'pdf' ? 'PDF' : 'DOCUMENT',
          assetType: AssetType.RAW_FILE,
          name: item.name,
          url: item.url,
          createdBy: item.userName,
          createdAt: toDateFormat(item.createdAt),
          knowledgeExtractionStatus:
            item.metadata?.knowledgeExtraction?.status || '',
          parentClientId: item.parentClient?.id,
          parentClientName: item.parentClient?.name,
          imageUrls: item.metadata?.imageUrls || [],
          externalAssetId: item.metadata?.externalAssetId,
          isLocked: item.isLocked ? item.isLocked : false,
        } as DataItem;
      });

    const hierarchy = assetsHierarchyList?.documentsHierarchy?.flat() || [];
    if (hierarchy.length === 0) {
      setDocumentList(documentList);
      return documentList;
    }

    const documentMap: Record<number, DataItem> = documentList.reduce(
      (acc: Record<number, DataItem>, document: DataItem) => {
        acc[document.id] = document;
        return acc;
      },
      {},
    );

    const sortedDocumentList = [];
    for (const id of hierarchy) {
      const document = documentMap[id];
      if (document) {
        sortedDocumentList.push(document);
      }
    }

    setDocumentList(sortedDocumentList);

    return sortedDocumentList;
  }, [brandAssets, assetsHierarchyList]);

  const imageItems = useMemo(() => {
    return brandAssets.reduce<DataItem[]>((accumulator, item) => {
      if (item.assetType === AssetType.PRODUCT) {
        accumulator.push({
          id: item.id,
          assetType: AssetType.PRODUCT,
          name: item?.name,
          url: item?.previewUrl || item?.url || '',
          createdBy: item.userName,
          createdAt: toDateFormat(item.createdAt),
          parentClientId: item.parentClient?.id,
          parentClientName: item.parentClient?.name,
          externalAssetId: item.metadata?.externalAssetId,
          isLocked: item.isLocked ? item.isLocked : false,
          thumbnailUrl: item?.thumbnailUrl || '',
        });
      }

      return accumulator;
    }, []);
  }, [brandAssets]);

  const videoItems = useMemo(() => {
    return brandAssets.reduce<DataItem[]>((accumulator, item) => {
      if (item.assetType === AssetType.VIDEO) {
        accumulator.push({
          id: item.id,
          assetType: AssetType.VIDEO,
          name: item?.name,
          url: item.previewUrl || '',
          createdBy: item.userName,
          createdAt: toDateFormat(item.createdAt),
          parentClientId: item.parentClient?.id,
          parentClientName: item.parentClient?.name,
          thumbnailUrl: item?.thumbnailUrl,
          isLocked: item.isLocked ? item.isLocked : false,
          externalAssetId: item.metadata?.externalAssetId,
        });
      }

      return accumulator;
    }, []);
  }, [brandAssets]);

  const knowledgeItems = useMemo(() => {
    const attributeItems = brandAssets.reduce<DataItem[]>(
      (accumulator, item) => {
        if (item.assetType === AssetType.KNOWLEDGE) {
          const typeLabel =
            knowledgeAttributeList?.find(
              knowledgeItem =>
                knowledgeItem.attributeName === item?.metadata?.type,
            )?.displayName ?? '';

          if (typeLabel)
            accumulator.push({
              id: item.id,
              assetType: AssetType.KNOWLEDGE,
              name: typeLabel,
              knowledgeCategory: item.metadata?.type,
              details: item.metadata?.value,
              createdAt: toDateFormat(item.createdAt),
              createdBy: item.userName,
              isLocked: item.isLocked ? item.isLocked : false,
              sourceDocument: {
                id: item.metadata?.sourceAssetId,
                label: item.metadata?.sourceFileName,
                imageUrls: item?.metadata?.imageUrls || [],
                externalAssetId: item?.metadata?.externalAssetId || '',
              },
              parentClientId: item.parentClient?.id,
              parentClientName: item.parentClient?.name,
            });
        }

        return accumulator;
      },
      [],
    );

    const hierarchy = assetsHierarchyList?.attributesHierarchy?.flat() || [];

    if (hierarchy.length === 0) return attributeItems;

    const attributeMap: Record<number, DataItem> = attributeItems.reduce(
      (acc: Record<number, DataItem>, attribute: DataItem) => {
        acc[attribute.id] = attribute;
        return acc;
      },
      {},
    );

    const sortedAttributeList = [];
    for (const id of hierarchy) {
      const attribute = attributeMap[id];
      if (attribute) {
        sortedAttributeList.push(attribute);
      }
    }

    return sortedAttributeList;
  }, [brandAssets, assetsHierarchyList?.attributesHierarchy, documentItems]);

  return {
    brandAssets: {
      colorItems,
      fontItems,
      logoItems,
      documentItems,
      imageItems,
      videoItems,
      knowledgeItems,
    },
    isLoadingBrandAssets: isLoadingBrandAssets || isAssetHierarchyLoading,
  };
};
