import { useEffect } from 'react';

import isNil from 'lodash/isNil';
import { InfiniteData, useQueryClient } from 'react-query';

import { useRecoilValue } from 'recoil';
import { workspace } from '@store/atoms/workspace';
import { SoketiResponse, usePusher } from '@hooks/use-pusher';
import { QUERY_KEYS } from '@constants/query-keys';
import {
  AssetListResponse,
  ExtractionResponse,
  ImageVideoResponse,
} from '../types';
import { KnowledgeExtractionStatus, SoketiEvents } from '../enums';

type ExtractBrandKnowledgeParams = {
  onExtractionComplete: (
    status:
      | KnowledgeExtractionStatus.SUCCESS
      | KnowledgeExtractionStatus.FAILURE,
    message?: string,
  ) => void;
};

export const useExtractBrandKnowledge = ({
  onExtractionComplete,
}: ExtractBrandKnowledgeParams) => {
  const queryClient = useQueryClient();
  const currentWorkspace = useRecoilValue(workspace);
  const workspaceId = currentWorkspace?.id;

  const { pusherChannel, bindEvent, unbindEvent } = usePusher(workspaceId);

  const handleKnowledgeExtractionEvent = (
    data: SoketiResponse<ExtractionResponse>,
  ) => {
    const brandKnowledgeResponse = data;
    const { content, error } = brandKnowledgeResponse.message;
    const clientId = content?.client_id;
    const chatMessage = content?.chat_message;
    const status = chatMessage?.status;
    const errorMessage = chatMessage?.error_message;

    if (status === KnowledgeExtractionStatus.FAILURE || error) {
      onExtractionComplete(KnowledgeExtractionStatus.FAILURE, errorMessage);

      return;
    }

    if (
      status === KnowledgeExtractionStatus.SUCCESS &&
      clientId === currentWorkspace?.id
    ) {
      queryClient.refetchQueries(QUERY_KEYS.BRAND_LIBRARY.BRAND_ASSETS_LIST);
      queryClient.refetchQueries(QUERY_KEYS.HIERARCHY.ASSETS_HIERARCHY);

      return onExtractionComplete(KnowledgeExtractionStatus.SUCCESS);
    }
  };

  const handleVideoProcessingEvents = (data: ImageVideoResponse) => {
    if (data.client_id !== currentWorkspace.id) return;

    queryClient.setQueryData<InfiniteData<AssetListResponse>>(
      QUERY_KEYS.BRAND_LIBRARY.BRAND_ASSETS_LIST,
      cache => {
        if (!cache?.pages) return cache;

        const updatedPages = cache.pages.map(page => {
          const updatedItems = page.items.map(item => {
            if (item.id === data.asset_id) {
              return {
                ...item,
                thumbnailUrl: data.thumbnailUrl ?? item.thumbnailUrl,
                previewUrl: data.previewUrl ?? item.previewUrl,
              };
            }
            return item;
          });

          return { ...page, items: updatedItems };
        });

        return { ...cache, pages: updatedPages };
      },
    );
  };

  useEffect(
    function brandKnowledgeEvent() {
      if (isNil(workspaceId) || isNil(pusherChannel)) return;

      bindEvent(
        `${SoketiEvents.KNOWLEDGE_EXTRACTION_PROGRESS}${workspaceId}`,
        handleKnowledgeExtractionEvent,
      );

      bindEvent(
        SoketiEvents.ASSET_THUMBNAIL_GENERATED,
        handleVideoProcessingEvents,
      );

      bindEvent(
        SoketiEvents.ASSET_PREVIEW_GENERATED,
        handleVideoProcessingEvents,
      );

      return () => {
        unbindEvent(
          `${SoketiEvents.KNOWLEDGE_EXTRACTION_PROGRESS}${workspaceId}`,
        );
        unbindEvent(SoketiEvents.ASSET_THUMBNAIL_GENERATED);
        unbindEvent(SoketiEvents.ASSET_PREVIEW_GENERATED);
      };
    },
    [workspaceId, pusherChannel],
  );
};
