import { useQuery } from 'react-query';
import { useSetRecoilState } from 'recoil';

import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { QUERY_KEYS } from '@constants/query-keys';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';
import { assetsHierarchy } from '@store/atoms/assets-hierarchy';

import { AssetsHierarchy } from '../types';

export const useGetAssetsHierarchy = (clientId: number) => {
  const client = createHttpClient();
  const setAssetsHierarchy = useSetRecoilState(assetsHierarchy);

  const query = useQuery(
    QUERY_KEYS.HIERARCHY.ASSETS_HIERARCHY,
    () => {
      return client<AssetsHierarchy>({
        url: END_POINTS.HIERARCHY.ASSETS_HIERARCHY(clientId),
      });
    },
    {
      enabled: !!clientId,
      onSuccess: hierarchy => {
        setAssetsHierarchy(hierarchy);
      },
      onError: (error: Error) => {
        showAlert({
          type: AlertType.ERROR,
          header: 'Failed to fetch assets hierarchy',
          message: `${error?.message || 'Request failed'}`,
        });
      },
    },
  );

  return query;
};
